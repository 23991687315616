import { useState } from 'react'

import { CardGroup, CardGroupOption, FeatureIcon, Modal, Text } from '@cutover/react-ui'
import { FileInputField, FormModal, SelectField, TextInputField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { AppMetaDataScriptFormType } from './scripts/app-meta-data/script-schema'
import { useRunAppMetaDataScript } from '../instance-settings/use-instance-settings'
import { useAccountsQuery } from 'main/services/queries/use-accounts'

export const ApplicationIntelligenceListPage = () => {
  const { t } = useLanguage('applicationIntelligence')
  const { data: accountsData } = useAccountsQuery()
  const scriptMutation = useRunAppMetaDataScript()
  const [scriptModalOpen, setScriptModalOpen] = useState(false)
  const [blowupPlanetModalOpen, setBlowupPlanetModalOpen] = useState(false)

  const onSubmitRunMetaDataScript = async (formData: AppMetaDataScriptFormType) => {
    scriptMutation.mutate({
      account_id: formData.account_id,
      source_url: formData.source_url,
      csv_files: formData.csv_files
    })
  }

  const accountOptions = accountsData?.accounts?.map(account => {
    return { label: account.name, value: account.id }
  })

  const ScriptCardOptions: CardGroupOption[] = [
    {
      key: 1,
      title: 'Initialize Workspace',
      subtitle: 'Populate a workspace with application templates',
      startComponent: <FeatureIcon icon="lightbulb" status="info" />
    },
    {
      key: 2,
      title: 'Developer mode',
      subtitle: 'debug options',
      startComponent: <FeatureIcon icon="server" status="info" />
    }
  ]

  const onScriptCardClick = (key: number) => {
    console.log(key)
    switch (key) {
      case 1:
        setScriptModalOpen(true)
        break
      case 2:
        setBlowupPlanetModalOpen(true)
        const audio = new Audio('/seismic-charge.mp3')
        audio.play()
        break
      default:
        //no op
        break
    }
  }

  return (
    <>
      <CardGroup cardsPerRow={2} onSelect={onScriptCardClick} options={ScriptCardOptions} />
      <FormModal<AppMetaDataScriptFormType>
        open={scriptModalOpen}
        onClose={() => setScriptModalOpen(false)}
        title={t('modals.createAppMetaData.title')}
        confirmText={t('modals.createAppMetaData.confirmationText')}
        confirmIcon="play"
        onSubmit={onSubmitRunMetaDataScript}
        defaultValues={{
          source_url: 'https://ds1mhzamp3.execute-api.eu-west-1.amazonaws.com/prod/data'
        }}
      >
        <TextInputField<AppMetaDataScriptFormType>
          name="source_url"
          label={t('modals.createAppMetaData.appMetaDataUrl')}
          value="https://ds1mhzamp3.execute-api.eu-west-1.amazonaws.com/prod/data"
        />
        <SelectField<AppMetaDataScriptFormType>
          name="account_id"
          options={accountOptions}
          label={t('modals.createAppMetaData.appMetaDataAccountId')}
        />

        <Text>{t('modals.createAppMetaData.csvFiles.publicCloud')}</Text>
        <FileInputField<AppMetaDataScriptFormType> name="csv_files.public_cloud" accept="text/csv" />

        <Text>{t('modals.createAppMetaData.csvFiles.onPremise')}</Text>
        <FileInputField<AppMetaDataScriptFormType> name="csv_files.on_prem" accept="text/csv" />

        <Text>{t('modals.createAppMetaData.csvFiles.privateCloud')}</Text>
        <FileInputField<AppMetaDataScriptFormType> name="csv_files.private_cloud" accept="text/csv" />

        <Text>{t('modals.createAppMetaData.csvFiles.thirdParty')}</Text>
        <FileInputField<AppMetaDataScriptFormType> name="csv_files.third_party" accept="text/csv" />
      </FormModal>
      <Modal hideFooter title={'=)'} open={blowupPlanetModalOpen} onClose={() => setBlowupPlanetModalOpen(false)}>
        <img
          src={
            'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeG5vMXlzZGFrZWt1N2NjazE2eW9mcGR6dTFzaGljMmhjZzRsMjNjMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3K0D1Dkqh9MOmLSjzW/giphy.gif'
          }
          alt="Death star blowing up planet"
        />
      </Modal>
    </>
  )
}
