import { useCallback, useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { Box, ColumnConfig, IconButton, Menu, MenuListItem, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { DataSource } from 'main/services/queries/types'
import { useDataSourcesQuery } from 'main/services/queries/use-data-sources'
import { usePermissions } from 'main/services/queries/use-permissions'

//TODO: delete this file unless it can be gutted/repurposed as a default page
export const WorkspaceDataSourcesList = () => {
  const { t } = useLanguage('dataSources')
  const { data } = useDataSourcesQuery()
  const items = data?.data_sources || []
  const customFieldsMap = data?.meta?.custom_fields_map || {}

  const permissions = usePermissions('data-sources')
  const canRead = permissions('show')
  const navigate = useNavigate()

  const isDatasourceItemMatch = useMatch({ path: '/app/:accountSlug/settings/datasources' })

  const menuItems = (id: number | null | undefined) => {
    if (!id || !customFieldsMap[String(id)]) return []
    const customFieldItems = customFieldsMap[String(id)]
    return customFieldItems.map(item => ({
      icon: 'custom-field',
      label: item.name,
      onClick: () => {
        handleClickCustomField(item.id, String(id))
      }
    }))
  }

  const dataSourcesColumns: ColumnConfig<DataSource>[] = useMemo(() => {
    const columns = [
      {
        header: t('dataSources.table.columnHeaders.id'),
        property: 'id',
        sortable: true,
        render: (datum: DataSource) => <Text>{datum.id}</Text>
      },
      {
        header: t('dataSources.table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('dataSources.table.columnHeaders.url'),
        property: 'url',
        sortable: true
      },
      {
        header: t('dataSources.table.columnHeaders.actions'),
        property: 'goToDataSource',
        render: (datum: DataSource) => {
          return customFieldsMap[String(datum?.id)] ? (
            <Box>
              <Menu
                trigger={
                  <IconButton
                    tertiary
                    label={customFieldsMap[String(datum?.id)]?.length == 1 ? 'Go to values' : 'Select a Custom Field'}
                    icon={customFieldsMap[String(datum?.id)]?.length == 1 ? 'link' : 'more-vertical'}
                    {...(customFieldsMap[String(datum?.id)]?.length == 1 && {
                      onClick: () => handleClickCustomField(customFieldsMap[String(datum?.id)][0].id, String(datum.id))
                    })}
                  />
                }
              >
                {customFieldsMap[String(datum?.id)]?.length > 1 &&
                  menuItems(datum?.id).map(item => (
                    <MenuListItem icon={item.icon} label={item.label} key={item.label} onClick={item.onClick} />
                  ))}
              </Menu>
            </Box>
          ) : null
        }
      }
    ]

    return columns
  }, [items])

  const handleClickCustomField = useCallback(
    (customFieldId: string, datasourceId: string) => {
      if (canRead && isDatasourceItemMatch) {
        navigate(`${isDatasourceItemMatch.pathname}/${datasourceId}?cf_id=${customFieldId}`)
      }
    },
    [canRead, isDatasourceItemMatch]
  )

  return <Table columns={dataSourcesColumns} data={items} />
}
