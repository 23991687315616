import { useEffect, useMemo, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { Heading } from 'grommet'

import { Box } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useDataSource } from 'main/services/queries/use-data-sources'
import { SubHeaderSearch } from 'main/components/layout/shared/sub-header'
import { useClearFilterParam, useFilters, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { DataSourceVisibilityToggle } from 'main/components/workspace/workspace-sub-header/datasource-visiblity-toggle'

export const WorkspaceDataSourcesSubHeader = () => {
  const { t } = useLanguage('dataSources')

  const isDatasourceItemMatch = useMatch({ path: '/app/:accountSlug/settings/datasources/:id' })

  const dataSourceId = isDatasourceItemMatch?.params?.id ? isDatasourceItemMatch.params.id : null
  const { data: dataSource } = useDataSource((dataSourceId as unknown as number) ?? null)
  const { filters } = useFilters()
  const setFilterParams = useSetFilterParams()
  const clearFilter = useClearFilterParam()
  const initialSearch = useMemo(() => filters.q, [])
  const [cfId, setCfId] = useState<string>('')
  const [tFilterId, setTfilterId] = useState<string | null>(null)

  useEffect(() => {
    const urlHashParts = location.hash.split('?')

    if (urlHashParts.length > 1) {
      for (let i = 1; i < urlHashParts.length; i++) {
        const searchParams = new URLSearchParams(urlHashParts[i])
        if (searchParams.get('cf_id')) {
          setCfId(searchParams.get('cf_id') || '')
        }

        if (searchParams.get('t_filter')) {
          setTfilterId(searchParams.get('t_filter') || '')
        } else {
          setTfilterId(null)
        }
      }
    }
  }, [location.hash])

  const handleSearch = (input?: string) => {
    if (input) {
      if (tFilterId) {
        setFilterParams({ q: input, cf_id: cfId, t_filter: tFilterId })
      } else {
        setFilterParams({ q: input, cf_id: cfId })
      }
    } else {
      clearFilter('q')
    }
  }

  return (
    <Box
      direction="row"
      css={`
        padding-top: 14px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 14px;
      `}
    >
      <Box direction="row">
        <Heading
          css={`
            font-size: 18px;
          `}
          responsive={false}
          margin={{ left: 'xsmall', right: 'none', vertical: 'none' }}
          level={'2'}
        >
          {dataSource ? dataSource?.data_source?.name : t('dataSources.header.title.label')}
        </Heading>
        {isDatasourceItemMatch && (
          <SubHeaderSearch
            placeholder={t('dataSources.searchDataSources', { dataSource: dataSource?.data_source?.name })}
            onSearch={handleSearch}
            initialValue={initialSearch as string}
          />
        )}
        <DataSourceVisibilityToggle />
      </Box>
    </Box>
  )
}
