import { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { RightPanel, Text } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'

export const HEADER_HEIGHT = 64

export const RunbookTemplatesWithDatasourcesPanel = memo(() => {
  const { templates } = useRightPanelTypeValue('runbook-templates-with-datasources')

  return templates ? <TemplatesWithDataSources templates={templates} /> : null
})

const TemplatesWithDataSources = memo(({ templates }: { templates: RunbookListRunbook[] }) => {
  const { t } = useLanguage('dataSources', { keyPrefix: 'workspacePanel' })
  const { closeRightPanel } = useSetActiveRightPanelState()

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const { toRunbook } = useRouting()

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  return (
    <RightPanel title={t('title')} onClose={closeRightPanel}>
      <>
        {templates?.length == 0 && <Text>No associated templates found.</Text>}
        {templates?.map(template => {
          return <RunbookListItem key={template.id} runbook={template} createLinkTo={createLinkTo} />
        })}
      </>
    </RightPanel>
  )
})
