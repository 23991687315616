import { useId, useMemo } from 'react'

import { Card, CardProps, Grid } from '@cutover/react-ui'

export type CardGroupOption = CardProps & { key: number }

export type CardGroupProps = {
  defaultValue?: string
  options: CardGroupOption[]
  cardsPerRow?: number
  onSelect?: (key: number) => void
}

export const CardGroup = ({ options, cardsPerRow = 2, onSelect, ...props }: CardGroupProps) => {
  const cardGroupId = useId()

  const opts = useMemo(
    () =>
      options.map(option => ({
        ...option,
        a11yTitle: option.title
      })),
    [options]
  )

  const numColumns = Math.min(cardsPerRow, options.length)

  return (
    <Grid
      id={cardGroupId}
      {...props}
      role="group"
      minWidth={200}
      style={{
        gridTemplateColumns: `repeat(${numColumns}, 1fr)`
      }}
    >
      {opts.map(option => (
        <Card
          {...option}
          onClick={() => {
            if (onSelect && option.key) {
              onSelect(option.key)
            }
          }}
        />
      ))}
    </Grid>
  )
}
