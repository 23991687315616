import { useEffect, useState } from 'react'

import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'

const DATASOURCE_VISIBILITY_STATUS = ['has_templates', 'no_templates'] as const

export const DataSourceVisibilityToggle = () => {
  const { t } = useLanguage('dataSources', { keyPrefix: 'visibilityToggle' })
  const setFilterParams = useSetFilterParams()
  const [cfId, setCfId] = useState<string | null>('')
  const [tFilterId, setTfilterId] = useState<string | null>('')
  const [queryInput, setQueryInput] = useState<string | null>('')

  type FilterValue = string | string[] | undefined
  interface FilterObject extends Record<string, FilterValue> {
    cf_id: string
    t_filter?: string
    q?: string
  }

  const handleClickOption = (status?: (typeof DATASOURCE_VISIBILITY_STATUS)[number]) => {
    var filterObject: FilterObject = { cf_id: cfId || '' }
    if (status) {
      filterObject = { ...filterObject, t_filter: status }
    }

    if (queryInput) {
      filterObject = { ...filterObject, q: queryInput }
    }

    setFilterParams(filterObject)
  }

  useEffect(() => {
    const urlHashParts = location.hash.split('?')

    if (urlHashParts.length > 1) {
      for (let i = 1; i < urlHashParts.length; i++) {
        const searchParams = new URLSearchParams(urlHashParts[i])
        if (searchParams.get('cf_id')) {
          setCfId(searchParams.get('cf_id'))
        }

        if (searchParams.get('q')) {
          setQueryInput(searchParams.get('q'))
        } else {
          setQueryInput(null)
        }

        if (searchParams.get('t_filter')) {
          setTfilterId(searchParams.get('t_filter'))
        } else {
          setTfilterId(null)
        }
      }
    }
  }, [location.hash])

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton label={t('all')} active={!tFilterId} onClick={() => handleClickOption()} />
      <SubHeaderButton
        label={t('hasTemplates')}
        active={tFilterId === 'has_templates'}
        onClick={() => handleClickOption('has_templates')}
      />
      <SubHeaderButton
        label={t('noTemplates')}
        active={tFilterId === 'no_templates'}
        onClick={() => handleClickOption('no_templates')}
      />
    </SubHeaderButtonGroup>
  )
}
