import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { QueryKeys } from './query-keys'

export function useDataSourceValuesQuery(params: any) {
  //TODO: replace all anys with response type or params type
  return useQuery<any, Error, any>([QueryKeys.DataSourceValues, params], async () => {
    const { data } = await apiClient.get<any>({
      url: 'data_source_values',
      params
    })

    return data
  })
}
