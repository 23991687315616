import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMatch } from 'react-router-dom'

import { ColumnConfig, Pill, Table, Text } from '@cutover/react-ui'
import { useDataSourceValuesQuery } from 'main/services/queries/use-data-source-values'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useCustomField } from 'main/services/queries/use-custom-fields'

export const WorkspaceDataSourceDetails = () => {
  const isDatasourceItemMatch = useMatch({ path: '/app/:accountSlug/settings/datasources/:id', end: false })
  const [input, setInput] = useState<string | null>('')
  const [cfId, setCfId] = useState<number>(0)
  const [tFilterId, setTfilterId] = useState<string | null>('')
  const { openRightPanel } = useSetActiveRightPanelState()

  const { data: customField } = useCustomField(cfId)
  const customAttributes =
    customField?.meta?.dependent_custom_field_value_keys?.sort((a: string, b: string) => a.localeCompare(b)) || []

  useEffect(() => {
    const urlHashParts = location.hash.split('?')

    if (urlHashParts.length > 1) {
      for (let i = 1; i < urlHashParts.length; i++) {
        const searchParams = new URLSearchParams(urlHashParts[i])
        if (searchParams.get('q')) {
          setInput(searchParams.get('q'))
        }

        if (searchParams.get('cf_id')) {
          setCfId(parseInt(searchParams.get('cf_id') ?? '0'))
        }

        if (searchParams.get('t_filter')) {
          setTfilterId(searchParams.get('t_filter'))
        } else {
          setTfilterId(null)
        }
      }
    }
  }, [location.hash])

  const { data } = useDataSourceValuesQuery({
    data_source_id: isDatasourceItemMatch?.params?.id,
    query: input || '',
    cf_id: cfId || ''
  })

  const dataSourceValues = data?.data_source_values

  const searchFilteredValues = input
    ? dataSourceValues?.filter((value: any) => value.key.toLowerCase().includes(input.toLocaleLowerCase()))
    : dataSourceValues

  // const templateFilteredValues = Object.fromEntries(
  //   Object.entries(searchFilteredValues ?? {}).filter(([_key, value]) => {
  //     return data.data_source_values_map.templates[(value as any)?.id]?.length > 0
  //   })
  // )

  const templateFilteredValues = tFilterId
    ? tFilterId === 'has_templates'
      ? searchFilteredValues?.filter((value: any) => data.data_source_values_map.templates[value.id].length > 0)
      : searchFilteredValues?.filter((value: any) => data.data_source_values_map.templates[value.id].length == 0)
    : searchFilteredValues

  const templatesData = data?.data_source_values_map?.templates

  const templatesColumn = useMemo(() => {
    return [
      {
        header: 'Templates',
        property: 'templates',
        sortable: false,
        render: (datum: any) => (
          <>
            {templatesData[datum.id].length > 0 && (
              <Pill
                color="primary"
                label={`${templatesData[datum.id].length} Template${templatesData[datum.id].length > 1 ? 's' : ''}`}
              />
            )}
            {templatesData[datum.id].length === 0 && <Text>{templatesData[datum.id].length}</Text>}
          </>
        )
      }
    ]
  }, [templatesData])

  const dataSourcesColumns: ColumnConfig<any>[] = useMemo(() => {
    const search_value = [
      {
        header: 'Search value',
        property: 'key',
        sortable: true,
        render: (datum: any) => (
          <Text>
            <strong>{datum.key}</strong>{' '}
          </Text>
        )
      }
    ]

    const customColumns =
      customAttributes?.map((attribute: string) => {
        return {
          header: attribute,
          property: attribute,
          sortable: true,
          render: (datum: any) => <Text>{datum.values[attribute]}</Text>
        }
      }) || []

    return search_value.concat(templatesColumn).concat(customColumns)
  }, [customAttributes, templatesColumn])

  const handleClickRow = useCallback(
    ({ datum }: { datum: any }) => {
      const templates = data.data_source_values_map.templates[datum.id]

      openRightPanel({ type: 'runbook-templates-with-datasources', templates: templates })
    },
    [data]
  )

  return dataSourceValues?.length > 0 ? (
    <Table
      columns={dataSourcesColumns}
      data={tFilterId ? templateFilteredValues : searchFilteredValues}
      onClickRow={handleClickRow}
    />
  ) : null
}
