import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { ApplicationIntelligenceHeader } from './application-intelligence-header/application-intelligence-header'

export const ApplicationIntelligenceLayout = () => {
  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return <Layout header={<ApplicationIntelligenceHeader />} filter={null} />
}
